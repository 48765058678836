import { isArray, valueIsObject } from "./utils";


export const project = (model: any, schema: any, indexes: Array<number> = null, iteration: number = null, ifEmptyFetchAll = false) => {
    if (model == null || schema == null) {
        return null;
    }

    if (valueIsPrimitive(model)) {
        return model;
    }

    const projectedModel = {
    };

    let projectSubset = false;
    if (indexes != null) {
        if (iteration == null) iteration = 0;
        projectSubset = true;
    }

    if (ifEmptyFetchAll === true) {
        if (isEmptyObject(schema)) {
            return model;
        }
    }

    for (var prop in schema) {
        if (model[prop] === undefined || schema[prop] === null) {
            continue;
        }
        
        if (model[prop] == null) {
            projectedModel[prop] = null;
            continue;
        }
        
        if (isArray(model[prop])) {
            const newArr = [];
            for (let c = 0; c < model[prop].length; c++) {
                if (projectSubset == false || c == indexes[iteration]) {
                    newArr.push(project(model[prop][c], schema[prop], indexes, iteration + 1, ifEmptyFetchAll));
                }
            }
            projectedModel[prop] = newArr;
        } else if (valueIsObject(model[prop])) {
            projectedModel[prop] = project(model[prop], schema[prop], indexes, iteration, ifEmptyFetchAll); 
        } else {
            projectedModel[prop] = model[prop];
        }
    }

    return projectedModel;
}

function valueIsPrimitive(value: any) {
    return !valueIsObject(value) && !isArray(value);
}

function isEmptyObject(obj: any) {
    for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }
    return true;
}

