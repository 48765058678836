import * as ThemeOptions from './options';

export abstract class IThemeSevice {
  abstract getThemeVariableValue(variable: string): string;
  abstract getButtonThemeOptions(): ThemeOptions.ButtonThemeOptions;
  abstract getCalendarThemeOptions(): ThemeOptions.CalendarThemeOptions;
  abstract getContainerControlThemeOptions(): ThemeOptions.ContainerControlThemeOptions;
  abstract getCheckBoxThemeOptions(): ThemeOptions.CheckBoxOptions;
  abstract getChartThemeOptions(): ThemeOptions.ChartThemeOptions;
  abstract getDataSourceGridThemeOptions(): ThemeOptions.DataSourceGridThemeOptions;
  abstract getDateTimeBoxThemeOptions(): ThemeOptions.DateTimeBoxThemeOptions;
  abstract getDropDownBoxThemeOptions(): ThemeOptions.DropDownBoxThemeOptions;
  abstract getFileAttachmentThemeOptions(): ThemeOptions.FileAttachmentThemeOptions;
  abstract getFormGroupThemeOptions(): ThemeOptions.FormGroupThemeOptions;
  abstract getGridThemeOptions(): ThemeOptions.GridThemeOptions;
  abstract getRepeaterControlThemeOptions(): ThemeOptions.RepeaterControlThemeOptions;
  abstract getImageBoxThemeOptions(): ThemeOptions.ImageBoxThemeOptions;
  abstract getIconismThemeOptions(): ThemeOptions.IconismThemeOptions;
  abstract getLabelThemeOptions(): ThemeOptions.LabelThemeOptions;
  abstract getLayoutThemeOptions(): ThemeOptions.LayoutThemeOptions;
  abstract getLinkThemeOptions(): ThemeOptions.LinkThemeOptions;
  abstract getListThemeOptions(): ThemeOptions.ListThemeOptions;
  abstract getMenuThemeOptions(): ThemeOptions.MenuThemeOptions;
  abstract getModalThemeOptions (): ThemeOptions.ModalThemeOptions;
  abstract getPanelThemeOptions (): ThemeOptions.PanelThemeOptions;
  abstract getPasswordTextboxThemeOptions(): ThemeOptions.PasswordTextboxThemeOptions;
  abstract getProgressBarThemeOptions(): ThemeOptions.ProgressBarThemeOptions;
  abstract getRadioButtonThemeOptions(): ThemeOptions.RadioButtonThemeOptions;
  abstract getRichTextBoxThemeOptions(): ThemeOptions.RichTextBoxThemeOptions;
  abstract getTableThemeOptions(): ThemeOptions.TableThemeOptions;
  abstract getTabContainerThemeOptions(orientation: string): ThemeOptions.TabContainerThemeOptions;
  abstract getTabHeaderThemeOptions(orientation: string): ThemeOptions.TabHeaderThemeOptions;
  abstract getTabContentThemeOptions(orientation: string): ThemeOptions.TabContentThemeOptions;
  abstract getTabPageThemeOptions(orientation: string): ThemeOptions.TabPageThemeOptions;
  abstract getTextAreaThemeOptions(): ThemeOptions.TextAreaThemeOptions;
  abstract getTextboxThemeOptions(): ThemeOptions.TextboxThemeOptions;
}
